import { GlobeIcon } from "@heroicons/react/outline";
import React from "react";
import DecorativeCurve from "../components/DecorativeCurve";
import Layout from "../components/Layout";
import Title from "../components/Title";

const IndexPage = () => {
  return (
    <Layout seo={{ title: "Home" }} hasDecoration={true}>
      <article className="flex flex-col items-center justify-center my-auto space-y-4 text-center h-72">
        <Title>Convert coordinates to WGS84</Title>
        <p>
          a web app based on{" "}
          <a href="https://github.com/wroge/wgs84" rel="noopener noreferrer nofollow">
            wroge/wgs84
          </a>
        </p>
        <GlobeIcon className={"text-green-500 mr-2 h-12 w-12"} />
      </article>
      <DecorativeCurve />
    </Layout>
  );
};

export default IndexPage;
